var generic = generic || {};
var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };

(function ($) {
  site.signin = {
    ...site.signin,
    isSignedIn: 0,
    $template: '',
    initSignInForm: function (context) {
      var self = this;
      var $login = $('.login', self.$template);
      var $registration = $('.registration', self.$template);
      var $form = $('#login__form', $login);
      var $submit = $('.js-signin-submit', $login);
      var $registrationSwitch = $('.js-register-switch', $login);
      var $returnUrl = $('input[name="RETURN_URL"]', $form);
      var $forgotPassword = $('.js-forgot-password', $login);
      var $hiddenSigninForm = $('.js-hidden_signin_form', context);
      var $hiddenForm = $('input.js-login-email', $login);
      var $passwordResetEmailNode = $('.js-password_reset_send__overlay_EMAIL_ADDRESS', context);
      var $forgotPasswordLink = $('.js-overlay-forgot-password');
      var $errors = $('.js-error-messages', context);
      var $passwordResetForm = $('.js-sign-in-page__overlay_password_reset_send form', context);
      var hash = generic.Hash();
      var param;
      var email;

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $returnUrl.val('/account/index.tmpl');

      // setup
      // $('.site-utils__item--account').trigger('click');
      // $registration.show();

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $('.js-login-email', $login),
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }

      $submit.once().click(function (e) {
        e.preventDefault();
        $(this).closest('#login__form').submit();

        return false;
      });

      $registrationSwitch.click(function (e) {
        $login.hide();
        $registration.show();
      });

      if ($hiddenSigninForm.length === 1) {
        if ($passwordResetEmailNode.length === 1) {
          $hiddenForm.once().on('change', function () {
            email = $hiddenForm.val();

            $passwordResetEmailNode.val(email);
          });
        }

        $forgotPasswordLink.on('click', function (e) {
          e.preventDefault();
          if ($passwordResetEmailNode.length === 1) {
            email = $hiddenForm.val();
            //$errors.empty();
            if (email.length < 1) {
              $errors.find('li.js-password_error_message').removeClass('hidden');
              $errors.find('li.js-password_reset_send_msg').addClass('hidden');

              return;
            }

            $passwordResetEmailNode.val(email);
            param = hash.queryToJson($passwordResetForm.serialize());
            Object.entries(param).forEach(([key, value]) => {
              param[key] = decodeURIComponent(value);
            });
            generic.jsonrpc.fetch({
              method: 'rpc.form',
              params: [param],
              onBoth: function (r) {
                var messages = r.getMessages();

                if (messages) {
                  messages.forEach(function (message) {
                    if (message.key === 'request_sent') {
                      $errors.find('li.js-password_reset_send_msg').removeClass('hidden');
                      $errors.find('li.js-password_error_message').addClass('hidden');
                    } else {
                      $errors.find('li.js-password_error_message').removeClass('hidden');
                      $errors.find('li.js-password_reset_send_msg').addClass('hidden');
                    }
                  });
                }
              }
            });
          }
        });
      }
    },
    initRegistrationForm: function () {
      var self = this;
      var $registration = $('.registration', self.$template);
      var $login = $('.login', self.$template);
      var $form = $('#registration__form', $registration);
      var $showPass = $('input[name="show_pass"]', $registration);
      var $password = $('input[name="PASSWORD"]', $registration);
      var $submit = $('.js-registration-submit', $registration);
      var $loginSwitch = $('.js-login-switch', $registration);
      var $returnUrl = $('input[name="RETURN_URL"]', $registration);

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $returnUrl.val('/account/index.tmpl');
      $showPass.removeAttr('checked');
      $showPass.click(function (e) {
        var isChecked = $(this).is(':checked');

        $password[0].type = isChecked ? 'text' : 'password';
      });

      $submit.once().click(function (e) {
        e.preventDefault();
        $(this).closest('#registration__form').submit();

        return false;
      });

      $loginSwitch.click(function (e) {
        $registration.hide();
        $login.show();
      });
    },
    initAccountLinks: function () {
      var self = this;
      var $login = $('.login', self.$template);
      var $registration = $('.registration', self.$template);
      var $account = $('.account-links', self.$template);
      var $welcome = $('.account-links__welcome', self.$template);
      var $userName = $('.account-links__name', self.$template);
      var $social_login = $('.social-login', self.$template);

      $(self.$template).addClass('is_signed_in');
      var user = site.userInfoCookie.getValue('first_name');

      if (!_.isUndefined(user) && !_.isNull(user)) {
        $userName.html(user);
        $welcome.show();
      } else {
        $welcome.hide();
      }
      $login.hide();
      $registration.hide();
      $social_login.hide();
      $account.show();
    },
    init: function () {
      var self = this;

      if (parseInt(site.signin.isSignedIn) === 0) {
        self.initSignInForm();
        self.initRegistrationForm();
      } else if (site.signin.isSignedIn === 1) {
        self.initAccountLinks();
      }
    }
  };

  Drupal.behaviors.gnavAccountV1 = {
    attach: function (context, settings) {
      site.signin.isSignedIn =
        typeof site.userInfoCookie === 'undefined' ||
        _.isUndefined(site.userInfoCookie.getValue('signed_in')) ||
        _.isNull(site.userInfoCookie.getValue('signed_in'))
          ? 0
          : site.userInfoCookie.getValue('signed_in');
      site.signin.$template = $('.sign-in', context);
      site.signin.init();

      var $thisContent = $('.js-gnav-util-trigger-account').next('.js-gnav-util__content');
      var disableGnavSignInOverlay = Drupal.settings.disable_gnav_signin_overlay;

      if (!disableGnavSignInOverlay) {
        $(document)
          .on('mouseover', '.js-site-header-utility-not-signed-in', function (evt) {
            evt.preventDefault();
            if ($('.gnav-util--account').length) {
              $('body').removeClass('mobile-gnav-active');
              $thisContent.removeClass('hidden');
            }
          })
          .on('mouseout', '.gnav-util__content--account', function (evt) {
            if (!$(evt.target).is('input, span')) {
              $(this).addClass('hidden');
            }
          });
      }

      // Forgot password section.
      $('.js-forgot-password-error', context)
        .once()
        .on('click', function (evt) {
          var $email = $('.js-login-email', context);
          var emailId = $email.val();

          evt.preventDefault();
          if (emailId.length < 1) {
            $('.js-error-messages', context).find('li').removeClass('hidden');
            $email.addClass('error');

            return null;
          }
          site.signin.requestPassword(emailId);
        });
    }
  };
})(jQuery);
